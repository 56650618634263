<template>
  <div>
    <!-- Modal de agregar y editar -->
    <div class="modal fade" id="modal_edit_cargue">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera pt-2 pb-2">
            <h4 class="modal-title text-white">Editar Cargue Programación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- Documento transporte -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Documento Transporte</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.doc_transporte"
                    :class="
                      $v.form.doc_transporte.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="
                      programacionExist(
                        'Documento Transporte',
                        form.doc_transporte
                      )
                    "
                  />
                </div>
              </div>
              <!-- Producto -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Producto</label>
                  <v-select
                    v-model="producto"
                    placeholder="Producto"
                    label="nombre"
                    :options="listasForms.productos"
                    class="form-control form-control-sm p-0"
                    :class="
                      $v.form.producto_id.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="selectProducto()"
                  >
                  </v-select>
                </div>
              </div>
              <!-- Vigencia Guía -->
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Vigencia Guía</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="form.vigencia_guia"
                    :class="
                      $v.form.vigencia_guia.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
              <!-- Sitio Cargue -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Sitio Cargue</label>
                  <v-select
                    v-model="sitio_cargue"
                    placeholder="Sitio Cargue"
                    label="nombre"
                    :options="listasForms.sitios"
                    class="form-control form-control-sm p-0"
                    :class="
                      $v.form.sitio_cargue_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="selectSitioCargue()"
                  ></v-select>
                </div>
              </div>
              <!-- Sitio Descargue -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Sitio Descargue</label>
                  <v-select
                    v-model="sitio_descargue"
                    placeholder="Sitio Descargue"
                    label="nombre"
                    :options="listasForms.sitios"
                    class="form-control form-control-sm p-0"
                    :class="
                      $v.form.sitio_descargue_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="selectSitioDescargue()"
                  ></v-select>
                </div>
              </div>
              <!-- Transportadora -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Transportadora</label>
                  <v-select
                    v-model="transportadora"
                    placeholder="Transportadora"
                    label="razon_social"
                    :options="listasForms.transportadoras"
                    class="form-control form-control-sm p-0"
                    :class="
                      $v.form.transportadora_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="selectTransportadora()"
                  ></v-select>
                </div>
              </div>
              <!-- Ruta -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Ruta</label>
                  <v-select
                    v-model="ruta"
                    placeholder="Ruta"
                    label="nombre"
                    :options="listasForms.rutas"
                    class="form-control form-control-sm p-0"
                    :class="
                      $v.form.ruta_id.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="selectRuta()"
                  >
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Conductor</label>
                  <input
                    v-model="form.cond_documento"
                    placeholder="Número Documento"
                    class="form-control form-control-sm pl-2"
                    type="number"
                    :class="
                      $v.form.cond_documento.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="selectConductor()"
                  />
                  <label class="text-gray">{{
                    conductor ? conductor.nombre : ""
                  }}</label>
                </div>
              </div>
              <!-- Vehículo -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Vehículo</label>
                  <input
                    v-model="form.placa_vh"
                    placeholder="Vehiculo"
                    class="form-control form-control-sm pl-2"
                    type="text"
                    :class="
                      $v.form.placa_vh.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @change="convertirMayusculas('vh')"
                    @blur="selectVehiculo()"
                  />
                </div>
              </div>
              <!-- Remolque -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Remolque</label>
                  <input
                    v-model="form.placa_rem"
                    placeholder="Remolque"
                    class="form-control form-control-sm pl-2"
                    type="text"
                    @change="convertirMayusculas('rem')"
                    @blur="selectRemolque()"
                  />
                </div>
              </div>
              <!-- Observaciones -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">Observaciones</label>
                  <textarea
                    v-model="form.observaciones"
                    placeholder="Observaciones"
                    class="form-control form-control-sm pl-2"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              !$v.form.$invalid &&
                $store.getters.can(
                  'hidrocarburos.cargueProgramaciones.saveIndividual'
                )
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, numeric } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "CargueProgramacionModal",
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        sitio_cargue_id: null,
        sitio_descargue_id: null,
        vehiculo_id: null,
        placa_vh: null,
        remolque_id: null,
        placa_rem: null,
        conductor_id: null,
        cond_documento: null,
        producto_id: null,
        transportadora_id: null,
        ruta_id: null,
        vigencia_guia: null,
        doc_transporte: null,
        observaciones: null,
      },
      cargue_programacion: {},
      sitio_cargue: {},
      sitio_descargue: {},
      conductor: [],
      vehiculo: {},
      remolque: {},
      producto: {},
      transportadora: {},
      ruta: {},
      listasForms: {
        sitios: [],
        vehiculos: [],
        remolques: [],
        productos: [],
        transportadoras: [],
        rutas: [],
        conductores: [],
      },
    };
  },
  validations: {
    form: {
      sitio_cargue_id: {
        required,
      },
      sitio_descargue_id: {
        required,
      },
      placa_vh: {
        required,
      },
      cond_documento: {
        required,
        numeric,
      },
      producto_id: {
        required,
      },
      transportadora_id: {
        required,
      },
      ruta_id: {
        required,
      },
      vigencia_guia: {
        required,
      },
      doc_transporte: {
        required,
      },
    },
  },
  methods: {
    async llenarModal(cargue) {
      this.$parent.cargando = true;
      this.cargue_programacion = cargue;
      await this.getData();
      await this.getSitio();
      await this.getTransportadora();
      await this.getVehiculo();
      await this.getRemolque();
      await this.getConductor();
      await this.getProducto();
      await this.getRuta();
      this.$parent.cargando = false;
    },

    async getData() {
      //Documento Transporte
      this.form.doc_transporte = this.cargue_programacion.doc_transporte;

      //Id Cargue
      this.form.id = this.cargue_programacion.id;

      //Sitio Cargue
      this.sitio_cargue = this.cargue_programacion.sitio_cargue
        ? this.cargue_programacion.sitio_cargue
        : null;
      this.form.sitio_cargue_id = this.cargue_programacion.sitio_cargue_id
        ? this.cargue_programacion.sitio_cargue_id
        : null;

      //Sitio Descargue
      this.sitio_descargue = this.cargue_programacion
        ? this.cargue_programacion.sitio_descargue
        : null;
      this.form.sitio_descargue_id = this.cargue_programacion
        ? this.cargue_programacion.sitio_descargue.id
        : null;

      //Transportadora
      this.transportadora = this.cargue_programacion.transportadora
        ? this.cargue_programacion.transportadora
        : null;
      this.form.transportadora_id = this.cargue_programacion.transportadora
        ? this.cargue_programacion.transportadora.id
        : null;

      //Conductor
      this.conductor = this.cargue_programacion.conductor
        ? this.cargue_programacion.conductor
        : [];
      this.form.conductor_id = this.cargue_programacion.conductor
        ? this.cargue_programacion.conductor.id
        : null;
      this.form.cond_documento = this.cargue_programacion.cond_documento;

      //Vehículo

      /* this.vehiculo = this.cargue_programacion.vehiculo
        ? this.cargue_programacion.vehiculo
        : null; */
      this.form.vehiculo_id = this.cargue_programacion.vehiculo
        ? this.cargue_programacion.vehiculo.id
        : null;
      this.form.placa_vh = this.cargue_programacion.placa_vh;

      //Remolque
      /* this.remolque = this.cargue_programacion.remolque
        ? this.cargue_programacion.remolque
        : null; */
      this.form.remolque_id = this.cargue_programacion.remolque
        ? this.cargue_programacion.remolque.id
        : null;
      this.form.placa_rem = this.cargue_programacion.placa_rem;

      //Producto
      this.producto = this.cargue_programacion.producto
        ? this.cargue_programacion.producto
        : null;
      this.form.producto_id = this.cargue_programacion.producto
        ? this.cargue_programacion.producto.id
        : null;

      //Ruta
      this.ruta = this.cargue_programacion.ruta
        ? this.cargue_programacion.ruta
        : null;
      this.form.ruta_id = this.cargue_programacion.ruta
        ? this.cargue_programacion.ruta.id
        : null;

      //Vigencia Guía
      this.form.vigencia_guia = this.cargue_programacion.vigencia_guia
        ? this.cargue_programacion.vigencia_guia
        : null;

      //Observaciones
      this.form.observaciones = this.cargue_programacion.observaciones;
    },

    async programacionExist(entidad, entidad_id) {
      let proExist = null;
      let vehiculo_id = entidad === "Vehiculo" ? entidad_id : null;
      let remolque_id = entidad === "Remolque" ? entidad_id : null;
      let conductor_id = entidad === "Conductor" ? entidad_id : null;
      let doc_transporte =
        entidad === "Documento Transporte" ? entidad_id : null;

      let params = {
        vehiculo_id,
        remolque_id,
        conductor_id,
        doc_transporte,
      };
      if (
        (entidad === "Documento Transporte" && entidad_id) ||
        entidad != "Documento Transporte"
      ) {
        await axios
          .get("/api/hidrocarburos/cargueProgramaciones/programacionExist", {
            params,
          })
          .then(async (response) => {
            proExist = response.data;

            if (proExist.length > 0) {
              switch (entidad) {
                case "Vehiculo":
                  this.form.vehiculo_id = null;
                  this.form.placa_vh = null;
                  this.vehiculo = {};
                  break;
                case "Conductor":
                  this.form.conductor_id = null;
                  this.form.cond_documento = null;
                  this.conductor = {};
                  break;
                case "Remolque":
                  this.form.remolque_id = null;
                  this.form.placa_rem = null;
                  this.remolque = {};
                  break;
                case "Documento Transporte":
                  this.form.doc_transporte = null;
                  break;
                default:
                  break;
              }

              await this.$swal({
                icon: "error",
                title:
                  "El " +
                  entidad +
                  " ya se encuentra con una programación activa.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },

    async getSitio() {
      let me = this;
      var url = "api/admin/sitios/lista?sitio";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?transportadora";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.transportadoras = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getVehiculo() {
      let me = this;
      var url = "api/admin/vehiculos/lista?vehiculo";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.vehiculos = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getRemolque() {
      let me = this;
      var url = "api/admin/remolques/lista?remolque";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.remolques = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getConductor() {
      let me = this;
      var url = "api/admin/conductores/lista?conductor";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.conductores = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getProducto() {
      let me = this;
      var url = "api/admin/productos/lista?producto";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.productos = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getRuta() {
      let me = this;
      var url = "api/admin/rutas/lista?ruta";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.rutas = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectSitioCargue() {
      this.form.sitio_cargue_id = null;
      if (this.sitio_cargue) {
        this.form.sitio_cargue_id = this.sitio_cargue.id;
      }
    },

    selectSitioDescargue() {
      this.form.sitio_descargue_id = null;
      if (this.sitio_descargue) {
        this.form.sitio_descargue_id = this.sitio_descargue.id;
      }
    },

    selectTransportadora() {
      this.form.transportadora_id = null;
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    async selectVehiculo() {
      this.form.vehiculo_id = null;
      let vehiculo = this.listasForms.vehiculos.find(
        (vehiculo) => vehiculo.placa === this.form.placa_vh
      );
      if (vehiculo) {
        this.form.vehiculo_id = vehiculo.id;
        await this.programacionExist("Vehiculo", this.form.vehiculo_id);
      }
      /* if (this.vehiculo) {
        this.form.vehiculo_id = this.vehiculo.id;
        await this.programacionExist("Vehiculo", this.form.vehiculo_id);
      } */
    },

    async selectConductor() {
      this.form.conductor_id = null;
      this.conductor = [];
      this.conductor = this.listasForms.conductores.find(
        (conductor) => conductor.numero_documento == this.form.cond_documento
      );
      if (this.conductor) {
        this.form.conductor_id = this.conductor.id;
        await this.programacionExist("Conductor", this.form.conductor_id);
      }
      /* if (this.conductor) {
        this.form.conductor_id = this.conductor.id;
        await this.programacionExist("Conductor", this.form.conductor_id);
      } */
    },

    async selectRemolque() {
      this.form.remolque_id = null;
      let remolque = this.listasForms.remolques.find(
        (remolque) => remolque.placa == this.form.placa_rem
      );
      if (remolque) {
        this.form.remolque_id = remolque.id;
        await this.programacionExist("Remolque", this.form.remolque_id);
      }
    },

    selectProducto() {
      this.form.producto_id = null;
      if (this.producto) {
        this.form.producto_id = this.producto.id;
      }
    },

    selectRuta() {
      this.form.ruta_id = null;
      if (this.ruta) {
        this.form.ruta_id = this.ruta.id;
      }
    },

    async save() {
      this.$parent.cargando = true;
      await axios({
        method: "PUT",
        url: "/api/hidrocarburos/cargueProgramaciones/saveIndividual",
        data: this.form,
      })
        .then(async (response) => {
          await this.$parent.getIndex();
          this.$refs.closeModal.click();
          await this.$swal({
            icon: "success",
            title: "Se actualizo los registros exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    convertirMayusculas(tipo) {
      switch (tipo) {
        case "rem":
          this.form.placa_rem = this.form.placa_rem.toUpperCase();
          break;
        case "vh":
          this.form.placa_vh = this.form.placa_vh.toUpperCase();
          break;
        default:
          break;
      }
    },
  },
};
</script>
